@import "variables";

// Set body and html
html,
body {
    min-height: 100%;
}
body {
    margin: 0;
}

// Set headings
@function headings($from: 1, $to: 6) {
    @if $from == $to {
        @return "h#{$from}";
    } @else {
        @return "h#{$from}," + headings($from + 1, $to);
    }
}
#{headings(1,6)} {
    color: $color-black-100;
}

// Set common values
a {
    color: $color-black-100;
    cursor: pointer;
    outline: none;
    text-decoration: underline;
    &:hover {
        color: $color-black-100;
    }
}

// Hide section
.hide {
    display: none;
}

// Text alignments
.text-left {
    text-align: left !important;
}
.text-center {
    text-align: center !important;
}
.text-right {
    text-align: right !important;
}

// Content alignment
.align-center {
    justify-content: center;
}
.align-left {
    justify-content: flex-start;
}
.align-right {
    justify-content: flex-end;
}

// Borders
.border-top {
    border-top: 1px solid $color-border;
}
.border-bottom {
    border-bottom: 1px solid $color-border;
}
.border-left {
    border-left: 1px solid $color-border;
}
.border-right {
    border-right: 1px solid $color-border;
}
.border-left-dashed {
    border-left: 1px dashed $color-border;
}
.border-top-dashed {
    border-top: 1px dashed $color-border;
}
.border-right-dashed {
    border-right: 1px dashed $color-border;
}
.border-bottom-dashed {
    border-bottom: 1px dashed $color-border;
}
.border-top-black {
    border-top: 1px solid $color-accent;
}
.border-bottom-black {
    border-bottom: 1px solid $color-accent;
}
.border-left-black {
    border-left: 1px solid $color-accent;
}
.border-right-black {
    border-right: 1px solid $color-accent;
}

.margin-left {
    margin-left: 6px !important;
}
.margin-right {
    margin-right: 6px !important;
}
.margin-zero {
    margin: 0px !important;
}

.margin-left-0 {
    margin-left: 0px !important;
}
.margin-right-0 {
    margin-right: 0px !important;
}
.margin-top-0 {
    margin-top: 0px !important;
}
.margin-bottom-0 {
    margin-bottom: 0px !important;
}

.margin-left-10 {
    margin-left: 10px !important;
}
.margin-right-10 {
    margin-right: 10px !important;
}
.margin-top-10 {
    margin-top: 10px !important;
}
.margin-bottom-10 {
    margin-bottom: 10px !important;
}

.margin-top-16 {
    margin-top: 16px !important;
}
.margin-left-16 {
    margin-left: 16px !important;
}
.margin-right-16 {
    margin-right: 16px !important;
}
.margin-bottom-16 {
    margin-bottom: 16px !important;
}

.margin-bottom-24 {
    margin-bottom: 24px !important;
}
.margin-auto-center {
    margin: 0 auto !important;
}

.button-margin-left {
    margin-left: 6px !important;
}
.button-margin-right {
    margin-right: 6px !important;
}

.padding-0 {
    padding: 0px !important;
}
.padding-16 {
    padding: 16px !important;
}
.padding-right-16 {
    padding-right: 16px !important;
}
.padding-left-16 {
    padding-left: 16px !important;
}
.padding-right-46 {
    padding-right: 46px !important;
}
.padding-left-46 {
    padding-left: 46px !important;
}
.padding-x-10 {
    padding: 10px 0 !important;
}

.full-width {
    min-width: 100% !important;
    max-width: 100% !important;
}

.desktop {
    padding: 0 130px;
    max-width: 1440px;
    margin: 0 auto;
}
.tablet {
    padding: 0 96px;
}
.mobile {
    padding: 0 16px;
}

.strink {
    padding: 0 130px;
}
.expand {
    padding: 0px;
}

.height-40-div {
    height: 40px;
}

.main-container {
    padding-top: 90px;
}

.green-underline-small {
    width: 40px;
    height: 4px;
    background-color: $color-primary;
}
.green-underline-regular {
    width: 60px;
    height: 4px;
    background-color: $color-primary;
}
.black-underline-small {
    width: 40px;
    height: 4px;
    background-color: $color-black;
}

.gray-background {
    background-color: rgba($color: $color-black, $alpha: 0.03);
}
.team-background {
    background-color: rgba($color: $color-black, $alpha: 0.03);
}
.yellow-light-background {
    background-color: $color-yellow-light;
}
.accent-background {
    background-color: $color-accent;
}

.action-tooltip {
    background-color: $color-black;
    font-size: $size-11;
    font-weight: $weight-500;
    color: $color-white-100;
    line-height: normal;
    margin: 0px !important;
}

.mat-tooltip {
    background-color: $color-black;
    font-size: $size-11;
    font-weight: $weight-500;
    padding: 4px 2px 4px 2px;
    margin: 5px !important;
}
.black-tooltip {
    background-color: $color-black;
    font-size: $size-13;
    font-weight: $weight-500;
}
.black-small-tooltip {
    background-color: $color-black;
    font-size: $size-11;
    font-weight: $weight-500;
    padding: 4px 2px 4px 2px;
    margin: 5px !important;
}

.tag {
    font-size: $size-13;
    font-weight: $weight-600;
    padding: 6px 16px 6px 16px;
    border-radius: 20px;
    vertical-align: middle;
}
.tag-green {
    background-color: $color-green;
    color: $color-white !important;
}
.tag-red {
    background-color: $color-red;
    color: $color-white !important;
}
.tag-yellow {
    background-color: $color-yellow;
    color: $color-white !important;
}
.tag-blue {
    background-color: $color-blue;
    color: $color-white !important;
}
.tag-purple {
    background-color: $color-purple;
    color: $color-white !important;
}
.tag-black {
    background-color: $color-black;
    color: $color-white !important;
}

.chip-tag {
    padding: 4px 10px 4px 10px;
    border-radius: 30px;
    font-weight: $weight-500 !important;
    font-size: $size-11 !important;
    margin-right: 6px;
    letter-spacing: 1px;
}
.activated-tag {
    background-color: #5fce50;
    color: $color-white !important;
}
.deactivated-tag {
    background-color: #e4dae2;
    color: $color-accent !important;
}
.paid-tag {
    background-color: #413c3c;
    color: $color-white !important;
}
.unpaid-tag {
    background-color: #ceb152;
    color: $color-white !important;
}
.partially-tag {
    background-color: #3cd1b8;
    color: $color-white !important;
}
.pending-tag {
    background-color: #8d66c1;
    color: $color-white !important;
}
.verified-tag {
    background-color: #5fce50;
    color: $color-white !important;
}
.idle-tag {
    background-color: #6866cc;
    color: $color-white !important;
}
.halt-tag {
    background-color: #ca5b5b;
    color: $color-white !important;
}
.waiting-tag {
    background-color: #8d66c1;
    color: $color-white !important;
}
.running-tag {
    background-color: #eb1793;
    color: $color-white !important;
}
.upcoming-tag {
    background-color: #66c193;
    color: $color-white !important;
}
.offline-tag {
    background-color: #3669f4;
    color: $color-white !important;
}
.stopped-tag {
    background-color: #f44336;
    color: $color-white !important;
}
.moving-tag {
    background-color: #00db00;
    color: $color-white !important;
}
.ongoing-tag {
    background-color: #7393f0;
    color: $color-white !important;
}
.confirmed-tag {
    background-color: #9ac166;
    color: $color-white !important;
}
.on-tag {
    background-color: #00db00;
    color: $color-white !important;
}
.off-tag {
    background-color: #f44336;
    color: $color-white !important;
}
.completed-tag {
    background-color: #ffe861;
    color: $color-black !important;
}
.cancelled-tag {
    background-color: #ed747d;
    color: $color-white !important;
}
.extended-tag {
    background-color: #5bd4d4;
    color: $color-white !important;
}
.reassigned-tag {
    background-color: #5057d6;
    color: $color-white !important;
}
.replaced-tag {
    background-color: #9ac166;
    color: $color-white !important;
}
.exchanged-tag {
    background-color: #d78b28;
    color: $color-white !important;
}
.verified-icon {
    color: #5fce50 !important;
}
.pending-icon {
    color: #8d66c1;
}
.error-icon {
    color: #f44336;
}
.offline-icon {
    color: #3649f4;
}
.stopped-icon {
    color: #f44336;
}
.moving-icon {
    color: #00db00;
}
.suggested-tag {
    padding: 4px 10px 4px 10px;
    border-radius: 4px;
    font-weight: $weight-400 !important;
    font-size: $size-11 !important;
}

.round-flat-full-button {
    min-width: 100% !important;
    max-width: 100% !important;
    border-radius: 30px !important;
    padding: 4px 26px 4px 26px !important;
    font-weight: $weight-600 !important;
    color: $color-primary !important;
    .mat-icon {
        font-size: 20px;
        width: unset;
        height: unset;
        line-height: unset;
        vertical-align: middle;
        margin-right: 4px;
        color: $color-white-100 !important;
    }
}
.round-flat-button {
    border-radius: 30px !important;
    font-weight: $weight-600 !important;
    color: $color-white-100 !important;
    .mat-icon {
        font-size: 20px;
        width: unset;
        height: unset;
        line-height: unset;
        vertical-align: middle;
        margin-right: 4px;
        color: $color-white-100 !important;
    }
}
.square-flat-button {
    border-radius: 4px !important;
    font-weight: $weight-600 !important;
    .mat-icon {
        font-size: 20px;
        width: unset;
        height: unset;
        line-height: unset;
        vertical-align: middle;
        margin-right: 4px;
        color: $color-white-100 !important;
    }
}
.square-stroked-button {
    border-radius: 4px !important;
    font-weight: $weight-600 !important;
    .mat-icon {
        font-size: 20px;
        width: unset;
        height: unset;
        line-height: unset;
        vertical-align: middle;
        margin-right: 4px;
        color: $color-white-100 !important;
    }
}
.full-square-flat-button {
    min-width: 100% !important;
    width: 100% !important;
    border-radius: 4px !important;
    font-weight: $weight-600 !important;
    text-transform: capitalize !important;
    height: 46px;
    .mat-icon {
        font-size: 20px;
        width: unset;
        height: unset;
        line-height: unset;
        vertical-align: middle;
        margin-right: 4px;
        color: $color-black-100 !important;
    }
}
.full-square-stroked-button {
    min-width: 100% !important;
    width: 100% !important;
    border-radius: 4px !important;
    font-weight: $weight-600 !important;
    text-transform: capitalize !important;
    height: 46px;
    .mat-icon {
        font-size: 20px;
        width: unset;
        height: unset;
        line-height: unset;
        vertical-align: middle;
        margin-right: 4px;
        color: $color-white-100 !important;
    }
}
.round-stroked-button {
    border-radius: 30px !important;
    font-weight: $weight-500 !important;
    border: 1px solid $color-primary !important;
    .mat-icon {
        font-size: 20px;
        width: unset;
        height: unset;
        line-height: unset;
        vertical-align: middle;
        margin-right: 4px;
    }
}
.round-small-button {
    border-radius: 4px !important;
    font-weight: $weight-600 !important;
    color: $color-white-100 !important;
    padding: 4px 12px 4px 12px !important;
    line-height: unset !important;
    .mat-icon {
        font-size: 20px;
        width: unset;
        height: unset;
        line-height: unset;
        vertical-align: middle;
        margin-right: 4px;
        color: $color-white-100 !important;
    }
    span {
        font-size: $size-13 !important;
    }
}

.store-button {
    button {
        text-align: left;
        padding: 10px 16px 10px 16px;
        margin-bottom: 10px;
        vertical-align: middle;
        width: 170px;
        border-radius: 4px;
        span {
            line-height: 0px;
        }
        img {
            width: 30px;
            height: 30px;
            object-fit: cover;
            margin-right: 5px;
        }
        table {
            width: 100%;
            tr {
                td {
                    vertical-align: middle !important;
                }
            }
        }
    }
}

.input-label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 6px;
    border-bottom: 1px solid $color-gray;
    padding: 20px 16px 0px 16px;
    border-radius: 3px 3px 0px 0px;
    background-color: $color-yellow-light;
    h4 {
        font-size: $size-15;
        font-weight: $weight-600;
        color: $color-black-100;
        margin: 0px;
        padding: 6px 10px 6px 10px;
        border-radius: 10px;
    }
}

.mat-form-field {
    width: 100%;
    margin: 6px 0px 6px 0px;
    mat-label {
        font-weight: $weight-500;
        font-size: $size-15;
    }
    .mat-error {
        font-weight: $weight-600;
        font-size: $size-11;
        strong {
            font-weight: $weight-700;
        }
    }
    .mat-hint {
        font-weight: $weight-600;
        font-size: $size-11;
        strong {
            font-weight: $weight-700;
        }
    }
    input {
        width: 100%;
        margin-top: 4px !important;
        font-weight: $weight-600;
        font-size: $size-15;
        color: $color-black-100;
    }
    .mat-select {
        width: 100%;
        font-weight: $weight-600 !important;
        font-size: $size-15 !important;
        color: $color-black-100;
        margin-top: 4px !important;
    }
    textarea {
        width: 100%;
        font-weight: $weight-600;
        font-size: $size-15;
        color: $color-black-100;
        resize: none !important;
    }
}
.mat-select-panel {
    .mat-option {
        font-weight: $weight-600 !important;
        font-size: $size-14 !important;
        &:hover {
            background-color: $color-primary-light !important;
        }
    }
    .mat-selected {
        color: $color-black-100 !important;
        background-color: $color-yellow-light !important;
        &:hover {
            background-color: $color-yellow-light !important;
        }
    }
}
.mat-form-field-appearance-fill {
    .mat-form-field-flex {
        background-color: $color-yellow-light !important;
    }
}
.mat-form-field-appearance-outline {
    .mat-form-field-outline {
        color: $color-accent;
    }
}
.mat-checkbox {
    label {
        font-weight: $weight-600;
        font-size: $size-14;
    }
}

.select-form-field {
    font-weight: $weight-700 !important;
    font-size: $size-15 !important;
    color: $color-black-100;
    input {
        margin-top: 1px !important;
    }
    .mat-select {
        margin-top: 0px !important;
    }
    .mat-form-field-underline {
        display: none;
    }
    .mat-form-field-flex {
        padding: 6px 10px 0px 10px !important;
    }
    .mat-select-arrow-wrapper {
        transform: none !important;
    }
    .mat-form-field-infix {
        border-top: 0px;
    }
    .mat-form-field-wrapper {
        padding-bottom: 0px;
    }
    .mat-form-field-prefix {
        color: $color-accent;
        top: 0.4em !important;
        .mat-icon {
            width: unset;
            height: unset;
            line-height: unset;
            font-weight: $weight-400;
            color: $color-accent;
            font-size: 22px;
            vertical-align: middle;
            margin-right: 10px;
        }
    }
    .mat-form-field-suffix {
        margin-left: 10px;
        top: 0 !important;
        .mat-icon-button {
            height: 26px !important;
            width: 26px !important;
            line-height: unset !important;
            vertical-align: middle !important;
            display: inline-block !important;
            .mat-icon {
                color: $color-accent;
                font-weight: $weight-600;
                font-size: 16px !important;
            }
            &:hover {
                background-color: $color-secondary;
            }
        }
    }
}

.mat-simple-snackbar {
    font-size: $size-13;
    font-weight: $weight-500;
}
.success-snackbar {
    background-color: $color-black;
    margin-bottom: 0px !important;
    span {
        color: $color-white-100;
    }
    button {
        color: $color-white-100;
        font-size: $size-13;
        font-weight: $weight-500;
    }
}
.error-snackbar {
    background-color: $color-black;
    margin-bottom: 0px !important;
    span {
        color: $color-white-100;
        font-weight: $weight-500;
    }
    button {
        border: 1px solid $color-white;
        color: $color-white-100;
        font-size: $size-13;
        font-weight: $weight-500;
    }
}
.white-snackbar {
    background-color: $color-white;
    @include box-shadow(0px 2px 5px 0px rgba(0, 0, 0, 0.25));
    span {
        color: $color-black-100;
    }
    button {
        span {
            color: $color-primary;
        }
        color: $color-primary;
        font-size: $size-13;
        font-weight: $weight-500;
    }
}
.primary-snackbar {
    background-color: $color-primary;
    @include box-shadow(0px 2px 5px 0px rgba(0, 0, 0, 0.25));
    span {
        color: $color-white-100;
    }
    button {
        span {
            color: $color-white;
        }
        color: $color-white;
        font-size: $size-13;
        font-weight: $weight-500;
    }
}

.small-select-form-field {
    font-weight: $weight-700 !important;
    font-size: $size-12 !important;
    color: $color-black-100;
    margin: 0px !important;
    span {
        font-weight: $weight-500 !important;
        font-size: $size-12 !important;
        color: $color-black-100 !important;
    }
    input {
        margin-top: 1px !important;
    }
    .mat-form-field-label {
        margin-top: 0px;
    }
    .mat-select {
        margin-top: 0px !important;
    }
    .mat-form-field-underline {
        display: none;
    }
    .mat-form-field-flex {
        padding: 2px 10px 0px 10px !important;
    }
    .mat-select-arrow-wrapper {
        transform: none !important;
    }
    .mat-form-field-infix {
        border-top: 0px;
        padding: 10px 0px 10px 0px !important;
        margin: 0px !important;
    }
    .mat-form-field-wrapper {
        padding-bottom: 0px;
        margin: 0px !important;
    }
    .mat-form-field-outline {
        color: $color-border !important;
    }
    .mat-form-field-prefix {
        top: 0.4em !important;
        .mat-icon-button {
            justify-content: flex-start !important;
            width: unset !important;
            margin-right: 6px;
            .mat-icon {
                width: unset;
                height: unset;
                color: $color-black-200 !important;
                font-size: 18px !important;
                vertical-align: middle;
            }
        }
    }
    .mat-form-field-suffix {
        margin-left: 10px;
        top: 0 !important;
        .mat-icon-button {
            height: 26px !important;
            width: 26px !important;
            line-height: unset !important;
            vertical-align: middle !important;
            display: inline-block !important;
            .mat-icon {
                color: $color-accent;
                font-weight: $weight-600;
                font-size: 16px !important;
            }
            &:hover {
                background-color: $color-secondary;
            }
        }
    }
}
.small-mat-select {
    .mat-option {
        font-weight: $weight-600 !important;
        font-size: $size-12 !important;
    }
}
.bottom-prefix-dropdown-small {
    margin-top: 32px;
    margin-left: -18px;
}

.small-select-form-field.invalid {
    .mat-form-field-outline {
        color: $color-red !important;
    }
}
.small-select-form-field.shake {
    .mat-form-field-outline {
        animation: shake-form-field 0.5s;
    }
}
@keyframes shake-form-field {
    0% {
        transform: translateX(0);
    }
    25% {
        transform: translateX(-4px);
    }
    50% {
        transform: translateX(4px);
    }
    75% {
        transform: translateX(-4px);
    }
    100% {
        transform: translateX(0);
    }
}

.field-value {
    label {
        font-size: $size-11 !important;
        font-weight: $weight-500;
        margin: 0px 0px 0px 0px;
        color: $color-black-100;
    }
    span {
        font-size: $size-13;
        font-weight: $weight-500;
        margin: 0px 0px 0px 0px;
        color: $color-black-100;
    }
    h1 {
        color: $color-black-100;
        font-weight: $weight-600;
        margin: 0px 0px 0px 0px;
    }
    h2 {
        color: $color-black-100;
        font-weight: $weight-600;
        margin: 0px 0px 0px 0px;
    }
    h3 {
        color: $color-black-100;
        font-weight: $weight-600;
        margin: 0px 0px 0px 0px;
    }
    h4 {
        font-size: $size-13;
        color: $color-black-300;
        font-weight: $weight-500;
        margin: 0px 0px 0px 0px;
    }
    h5 {
        color: $color-black-100;
        font-weight: $weight-600;
        margin: 0px 0px 0px 0px;
    }
    h6 {
        color: $color-black-100;
        font-weight: $weight-600;
        margin: 0px 0px 0px 0px !important;
    }
    small {
        font-size: $size-13;
        font-weight: $weight-500;
        margin: 0px 0px 0px 0px;
        color: $color-black-300;
    }
    p {
        font-size: $size-13;
        font-weight: $weight-500;
        margin: 0px 0px 0px 0px;
        color: $color-black-300;
    }
}

.dropdown-menu {
    margin-top: 16px;
    border-radius: 10px !important;
    min-width: 200px !important;
    max-width: 600px !important;
    @include box-shadow(0px 0px 8px 0px rgba(0, 0, 0, 0.1));
    .mat-menu-content {
        padding: 0px !important;
        .profile-box {
            .profile {
                padding: 5px 16px 5px 16px;
                .profile-image {
                    .mat-icon {
                        width: unset;
                        height: unset;
                        font-weight: $weight-200 !important;
                        font-size: 48px !important;
                        color: rgba($color: $color-black, $alpha: 0.9) !important;
                    }
                    img {
                        width: 48px;
                        height: 48px;
                        border-radius: 50%;
                        object-fit: cover;
                    }
                }
                h3 {
                    font-weight: $weight-600;
                    font-size: $size-15;
                    margin: 8px 0px 8px 0px;
                    color: $color-black-100;
                }
            }
            .menu-items {
                .mat-menu-item {
                    padding: 10px 16px 10px 16px;
                    line-height: 0px;
                    border-top: 1px solid rgba($color: $color-black, $alpha: 0.08);
                    font-size: $size-14;
                    font-weight: $weight-500;
                    color: $color-black-200;
                    .mat-icon {
                        color: $color-black-200;
                        width: unset;
                        height: unset;
                        line-height: unset;
                        vertical-align: middle;
                        margin: 0px 10px 0px 0px;
                    }
                    &:hover {
                        background-color: $color-yellow-light;
                    }
                }
            }
        }
    }
}

.eazy-search {
    border-top: 1px solid $color-border;
}

.page-title {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: 16px;
    padding-bottom: 16px;
    .mat-display-1 {
        color: $color-black-100;
        font-weight: $weight-600 !important;
        margin: 0px;
    }
    h1 {
        color: $color-black-100;
        font-weight: $weight-600;
        margin: 0px;
    }
    h2 {
        color: $color-black-100;
        font-weight: $weight-600;
        margin: 0px;
    }
    h3 {
        color: $color-black-100;
        font-weight: $weight-500;
        margin: 0px;
    }
    p {
        color: $color-black-300;
        font-weight: $weight-600;
        margin: 6px 0px 6px 0px;
        font-size: $size-13;
        b {
            font-weight: $weight-600;
        }
        .mat-icon {
            width: unset;
            height: unset;
            line-height: unset;
            vertical-align: middle;
            margin: 0px 6px 0px 6px;
        }
    }
    small {
        font-size: $size-12;
        color: $color-black-200;
        font-weight: $weight-400;
    }
    .full-square-stroked-button {
        background-color: $color-white !important;
        color: $color-accent;
        border-radius: 30px !important;
        .mat-icon {
            color: $color-accent !important;
            margin: 0px 6px 0px 6px;
        }
    }
    .mat-icon-button {
        background-color: $color-white !important;
        color: $color-accent;
        .mat-icon {
            color: $color-accent !important;
        }
    }
}
.page-title-small {
    padding: 16px 16px 16px 16px;
    display: block !important;
}
.search-menubar {
    padding: 0px 0px 0px 0px;
    margin: 0px 0px 16px 0px;
    border-bottom: 2px solid $color-accent;
    .mat-flat-button {
        width: 100%;
        height: 46px;
        border-radius: 0px;
    }
}

.section-div {
    margin: 50px 0px 50px 0px;
    .section-header {
        text-align: center;
        h1 {
            font-weight: $weight-600;
            color: $color-black;
            margin: 0px;
        }
        small {
            font-size: $size-12;
            font-weight: $weight-500;
            color: $color-gray;
            margin: 0px;
        }
        .double-line {
            width: 50px;
            margin: 16px auto;
            border-bottom: 1px solid $color-primary;
        }
    }
    .section-body {
        padding: 30px 0px 30px 0px;
    }
}

.list-container {
    .sort-header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 16px;
        height: 24px;
        h3 {
            font-weight: $weight-500;
            color: $color-black-100;
            margin: 0px 0px 0px 0px;
            line-height: normal;
            b {
                font-weight: $weight-600;
            }
        }
        .mat-button {
            margin: 0px;
            padding: 4px 10px 4px 10px;
            line-height: unset !important;
            font-size: $size-12;
            font-weight: $weight-500;
            text-transform: uppercase;
            color: $color-red !important;
            .mat-icon {
                width: unset;
                height: unset;
                line-height: unset;
                font-size: 18px;
                color: $color-red;
                vertical-align: middle;
            }
        }
    }
    .filter-box {
        margin: 0px 0px 0px 0px;
        border-radius: 10px;
        padding: 16px;
        @include box-shadow(0px 0px 8px 0px rgba(0, 0, 0, 0.1));
        .filter-box-head {
            display: flex;
            justify-content: space-between;
            margin-bottom: 16px;
            h3 {
                font-weight: $weight-600;
                color: $color-black-100;
                margin: 0px 0px 10px 0px;
                line-height: normal;
            }
            .mat-button {
                margin: 0px;
                padding: 4px 10px 4px 10px;
                line-height: unset !important;
                font-size: $size-12;
                font-weight: $weight-500;
                text-transform: uppercase;
                color: $color-green !important;
                .mat-icon {
                    width: unset;
                    height: unset;
                    line-height: unset;
                    font-size: 18px;
                    color: $color-green;
                }
            }
        }
        .tags-box {
            border-bottom: 1px solid $color-border;
            padding-bottom: 6px;
            .tag {
                display: inline-block;
                background-color: $color-white;
                border-radius: 10px;
                border: 1px solid $color-border;
                text-align: center;
                padding: 8px 16px 8px 16px;
                font-size: $size-13;
                font-weight: $weight-600;
                color: $color-black-100;
                margin: 0px 10px 6px 0px;
                cursor: pointer;
            }
        }
        .meta-box {
            border-bottom: 1px solid $color-border;
            padding-top: 10px;
            padding-bottom: 10px;
            .item {
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                padding: 6px 6px 6px 6px;
                .icon {
                    margin-right: 10px;
                    .mat-icon {
                        height: unset;
                        width: unset;
                        line-height: unset;
                        vertical-align: middle;
                        font-size: 18px;
                        color: $color-red;
                    }
                }
                .meta {
                    h4 {
                        font-weight: $weight-600;
                        color: $color-black-100;
                        display: inline-block;
                        margin: 0px 0px 0px 0px;
                        line-height: normal;
                        font-size: $size-13;
                        span {
                            margin-left: 6px;
                            color: $color-red;
                        }
                    }
                }
            }
        }
        .slider-box {
            border-bottom: 1px solid $color-border;
            padding-top: 16px;
            padding-bottom: 16px;
            h4 {
                font-weight: $weight-600;
                color: $color-black-100;
                display: inline-block;
                margin: 0px 0px 0px 0px;
                line-height: normal;
                span {
                    margin-left: 6px;
                    font-weight: $weight-600;
                    font-size: $size-13;
                    color: $color-blue;
                }
            }
            .mat-slider {
                width: 100%;
            }
            .slider-values {
                display: flex;
                align-items: center;
                justify-content: space-between;
                span {
                    font-weight: $weight-600;
                    font-size: $size-13;
                    color: $color-black-300;
                }
            }
        }
        .transmission-box {
            border-bottom: 1px solid $color-border;
            padding-top: 16px;
            padding-bottom: 16px;
            h4 {
                font-weight: $weight-600;
                color: $color-black-100;
                display: inline-block;
                margin: 0px 0px 10px 0px;
                line-height: normal;
                font-size: $size-13;
                span {
                    margin-left: 6px;
                    font-weight: $weight-600;
                    font-size: $size-13;
                    color: $color-red;
                }
            }
            h5 {
                color: $color-black-100;
                display: inline-block;
                margin: 0px 0px 0px 6px;
                line-height: normal;
                font-weight: $weight-600;
                font-size: $size-13;
            }
            .mat-button-toggle-group {
                border-color: $color-accent;
                .mat-button-toggle {
                    .mat-button-toggle-label-content {
                        padding: 0px 10px 0px 10px;
                        font-size: $size-14;
                        font-weight: $weight-600;
                        color: $color-black-100;
                    }
                }
                .mat-button-toggle-checked {
                    background-color: $color-primary;
                }
            }
        }
        .capacity-box {
            border-bottom: 1px solid $color-border;
            padding-top: 16px;
            padding-bottom: 16px;
            h4 {
                font-weight: $weight-600;
                color: $color-black-100;
                display: inline-block;
                margin: 0px 0px 0px 0px;
                line-height: normal;
                font-size: $size-13;
                span {
                    font-weight: $weight-600;
                    font-size: $size-13;
                    color: $color-red;
                }
            }
            h5 {
                color: $color-black-100;
                display: inline-block;
                margin: 0px 0px 0px 6px;
                line-height: normal;
                font-weight: $weight-600;
                font-size: $size-13;
            }
            .select-form-field {
                max-width: 80px;
            }
            .mat-radio-button {
                font-size: $size-14;
                font-weight: $weight-600;
                color: $color-black-100;
                margin: 0px 16px 0px 16px;
            }
        }
        .models-box {
            border-bottom: 1px solid $color-border;
            padding-top: 16px;
            padding-bottom: 16px;
            h4 {
                font-weight: $weight-600;
                color: $color-black-100;
                display: inline-block;
                margin: 0px 0px 0px 0px;
                line-height: normal;
                font-size: $size-13;
                span {
                    font-weight: $weight-600;
                    font-size: $size-13;
                    color: $color-blue;
                }
            }
            h5 {
                color: $color-black-100;
                display: inline-block;
                margin: 0px 0px 0px 6px;
                line-height: normal;
                font-weight: $weight-600;
                font-size: $size-13;
            }
            .select-form-field {
                max-width: 100%;
            }
        }
        .duration-box {
            margin: 10px 0px 0px 0px;
            text-align: center;
            padding: 5px;
            background-color: rgba($color: $color-black, $alpha: 0.05);
            label {
                font-weight: $weight-600;
                font-size: $size-13;
                color: $color-black-400;
            }
            h3 {
                font-weight: $weight-600;
                color: $color-black-100;
                margin: 10px 0px 0px 0px;
                line-height: normal;
            }
        }
        .address-box {
            margin: 16px 0px 0px 0px;
            h5 {
                font-weight: $weight-600;
                color: $color-black-100;
                margin: 16px 0px 2px 0px;
            }
            label {
                font-weight: $weight-600;
                font-size: $size-13;
                color: $color-black-400;
                padding-bottom: 5px;
                border-bottom: 1px solid $color-primary;
            }
            small {
                font-weight: $weight-500;
                font-size: $size-11;
                color: $color-black-400;
                line-height: normal;
            }
        }
    }
    .list-box {
        margin: 0px 0px 0px 0px;
        .item-box {
            margin: 0px 0px 16px 16px;
            border-radius: 10px;
            overflow: hidden;
            cursor: pointer;
            @include box-shadow(0px 0px 8px 0px rgba(0, 0, 0, 0.2));
            .item-head {
                background-color: $color-white;
                text-align: center;
                padding: 16px 10px 10px 10px;
                h4 {
                    font-weight: $weight-600;
                    color: $color-black-100;
                    margin: 0px;
                    outline: none;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    display: block;
                    overflow: hidden;
                    line-height: normal;
                }
            }
            .item-image {
                background-color: $color-light;
                height: 140px;
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                padding: 10px 20px 10px 20px;
                position: relative;
                img {
                    height: 100%;
                    object-fit: contain;
                }
                small {
                    font-weight: $weight-500;
                    font-size: $size-11;
                    color: $color-black-300;
                    margin: 0px;
                }
                .count-label {
                    position: absolute;
                    top: 10px;
                    left: 0;
                    span {
                        background-color: $color-accent;
                        font-size: $size-11;
                        font-weight: $weight-500;
                        color: $color-white;
                        padding: 4px 8px 4px 8px;
                        border-radius: 0px 4px 4px 0px;
                    }
                }
            }
            .item-meta {
                padding: 10px 10px 10px 10px;
                text-align: center;
                h4 {
                    font-weight: $weight-600;
                    color: $color-black-100;
                    margin: 0px;
                    line-height: normal;
                }
                h5 {
                    font-weight: $weight-600;
                    font-size: $size-13;
                    color: $color-black-100;
                    margin: 0px;
                    line-height: normal;
                }
                h6 {
                    font-weight: $weight-500;
                    color: $color-black-400;
                    font-size: $size-13;
                    margin: 10px 0px 5px 0px;
                    line-height: normal;
                }
                i {
                    font-weight: $weight-500;
                    color: $color-black-400;
                    font-size: $size-11;
                }
            }
            .item-price {
                display: flex;
                align-items: center;
                flex-direction: column;
                padding: 8px 10px 10px 10px;
                background-color: $color-primary-light;
                .kilometer {
                    text-align: center;
                    span {
                        font-size: $size-12;
                        font-weight: $weight-600;
                    }
                    small {
                        font-size: $size-11;
                        font-weight: $weight-500;
                        font-style: italic;
                    }
                    h4 {
                        font-weight: $weight-600;
                        color: $color-black-100;
                        margin: 0px;
                        line-height: normal;
                        display: inline-block;
                    }
                    h5 {
                        font-weight: $weight-500;
                        color: $color-black-100;
                        font-size: $size-13;
                        margin: 0px;
                        line-height: normal;
                        display: inline-block;
                        span {
                            font-weight: $weight-500;
                            color: $color-black-300;
                            font-size: $size-11;
                        }
                    }
                }
            }
            .item-location {
                border-top: 1px dashed $color-border;
                padding: 10px 10px 10px 10px;
                text-align: center;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                span {
                    font-size: $size-11;
                    font-weight: $weight-500;
                    color: $color-black-300;
                    margin: 0px;
                    line-height: normal;
                    .mat-icon {
                        width: unset;
                        height: unset;
                        color: $color-blue;
                        font-size: $size-16;
                        vertical-align: top;
                    }
                }
                a {
                    outline: none;
                    text-decoration: none;
                    font-size: $size-11;
                    font-weight: $weight-500;
                    color: $color-primary;
                }
                small {
                    font-size: $size-13;
                    font-weight: $weight-600;
                    margin: 0px;
                    line-height: normal;
                }
                .green {
                    color: $color-green;
                }
                .red {
                    color: $color-red;
                }
                .blue {
                    color: $color-blue;
                }
            }
            .item-button {
                display: flex;
                justify-content: space-between;
                align-items: center;
                background-color: $color-accent;
                padding: 8px 10px 8px 10px;
                .price {
                    h2 {
                        font-weight: $weight-600;
                        color: $color-white-100;
                        margin: 0px 10px 0px 0px;
                        line-height: normal;
                        display: inline-block;
                    }
                    h5 {
                        font-weight: $weight-600;
                        color: $color-white-100;
                        margin: 0px 0px 0px 0px;
                        line-height: normal;
                        display: inline-block;
                    }
                    span {
                        font-size: $size-13;
                        font-weight: $weight-600;
                        color: $color-white-300;
                        margin: 0px;
                        line-height: normal;
                    }
                }
                .mat-flat-button {
                    border-radius: 30px;
                    padding-left: 20px;
                    padding-right: 20px;
                }
                .luxury {
                    small {
                        color: $color-white;
                    }
                }
            }
            .date-box {
                padding: 16px;
                label {
                    font-weight: $weight-600;
                    font-size: $size-13;
                    color: $color-white;
                    background-color: $color-primary;
                    padding: 5px 10px 5px 10px;
                }
                .date-metadata {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    margin: 10px 0px 10px 0px;
                    .short-date {
                        margin-right: 6px;
                        .mat-display-2 {
                            margin: 0px;
                            font-weight: $weight-600;
                            color: $color-black-100;
                            line-height: normal;
                        }
                    }
                    .long-date {
                        small {
                            font-size: $size-13;
                            font-weight: $weight-500;
                            margin: 4px 0px 4px 0px;
                            color: $color-black-100;
                            line-height: normal;
                            display: block;
                        }
                        span {
                            font-size: $size-13;
                            font-weight: $weight-600;
                            color: $color-black-100;
                            margin: 0px;
                            line-height: normal;
                            display: block;
                        }
                    }
                }
                h5 {
                    font-size: $size-14;
                    font-weight: $weight-600;
                    color: $color-black-100;
                    margin: 0px;
                }
                p {
                    font-size: $size-13;
                    font-weight: $weight-500;
                    margin: 6px 0px 0px 0px;
                    color: $color-black-400;
                }
            }
            .duration-box {
                padding: 76px 16px 16px 16px;
                h3 {
                    font-weight: $weight-600;
                    color: $color-black-100;
                    margin: 0px;
                }
            }
            .extra-box {
                padding: 16px 16px 16px 16px;
                i {
                    font-weight: $weight-500;
                    color: $color-black-300;
                    font-size: $size-11;
                }
            }
            .instruction-box {
                padding: 16px 16px 16px 16px;
                h3 {
                    margin: 0px 0px 10px 0px;
                    font-weight: $weight-500;
                    color: $color-black-100;
                }
                .instructions {
                    ul {
                        margin: 0px;
                        padding-inline-start: 16px;
                        li {
                            padding: 0px;
                            font-size: $size-11;
                            font-weight: $weight-600;
                            color: $color-black-300;
                        }
                    }
                }
            }
            &:hover {
                @include box-shadow(0px 2px 8px 0px rgba(0, 0, 0, 0.2));
            }
        }
    }
}
.sold {
    cursor: default !important;
    user-select: none !important;
    pointer-events: none !important;
}

.content-container {
    margin: 0px 0px 30px 0px;
    .section-title {
        h4 {
            font-weight: $weight-500;
            color: $color-black-100;
            margin: 0px;
            line-height: normal;
        }
        span {
            font-weight: $weight-500;
            font-size: $size-13;
            color: $color-black-300;
            b {
                font-weight: $weight-600;
            }
        }
    }
    .navigation-box {
        background-color: rgba($color: $color-black, $alpha: 0.05);
        margin: 10px 0px 0px 10px;
        font-weight: $weight-600;
        font-size: $size-13;
        color: $color-gray;
        padding: 16px;
    }
    .content-box {
        margin: 10px 0px 0px 0px;
        padding: 26px 26px 26px 26px;
        border-radius: 10px;
        @include box-shadow(0px 0px 8px 0px rgba(0, 0, 0, 0.14));
        .booking-details-box {
            .sections-box {
                a {
                    outline: none;
                    text-decoration: none;
                    display: inline-block;
                    &:hover {
                        text-decoration: underline;
                        text-decoration-color: $color-blue;
                    }
                }
                .section-3 {
                    display: flex;
                    align-items: flex-start;
                    justify-content: flex-start;
                    margin-bottom: 20px;
                    padding-bottom: 10px;
                    border-bottom: 1px dashed $color-border;
                    .icon {
                        margin-right: 10px;
                        .mat-icon {
                            width: unset;
                            height: unset;
                            line-height: unset;
                            vertical-align: middle;
                            color: $color-accent;
                        }
                    }
                    .meta {
                        h4 {
                            font-weight: $weight-500;
                            color: $color-black-100;
                            font-size: $size-15;
                            margin: 0px 0px 0px 0px;
                            line-height: normal;
                            span {
                                margin-left: 6px;
                                font-weight: $weight-600;
                                font-size: $size-15;
                                color: $color-black;
                            }
                            .time-tag {
                                // border-radius: 4px;
                                // background-color: $color-accent;
                                // padding: 4px 8px 4px 8px;
                                color: $color-red;
                                border-bottom: 2px solid $color-accent;
                            }
                            a {
                                text-decoration: none;
                                outline: none;
                            }
                        }
                        h3 {
                            font-weight: $weight-700;
                            color: $color-black-100;
                            margin: 0px 0px 0px 0px;
                            line-height: normal;
                            span {
                                margin-left: 6px;
                                font-weight: $weight-500;
                                font-size: $size-13;
                                color: $color-black-300;
                            }
                        }
                        small {
                            font-weight: $weight-500;
                            font-size: $size-13;
                            color: $color-black-100;
                        }
                    }
                    .action {
                        margin-left: 10px;
                        .mat-icon {
                            width: unset;
                            height: unset;
                            line-height: unset;
                            vertical-align: middle;
                            cursor: pointer;
                        }
                    }
                }
                .section-2 {
                    display: flex;
                    align-items: flex-start;
                    justify-content: flex-start;
                    margin-bottom: 10px;
                    .icon {
                        margin-right: 10px;
                        .mat-icon {
                            width: unset;
                            height: unset;
                            line-height: unset;
                            vertical-align: middle;
                            color: $color-black-100;
                        }
                    }
                    .meta {
                        h4 {
                            font-weight: $weight-500;
                            color: $color-black-100;
                            font-size: $size-15;
                            margin: 0px 0px 0px 0px;
                            line-height: normal;
                        }
                        span {
                            font-weight: $weight-600;
                            font-size: $size-13;
                            color: $color-green;
                        }
                        h3 {
                            font-weight: $weight-500;
                            color: $color-black-100;
                            margin: 0px 0px 0px 0px;
                            line-height: normal;
                        }
                        ul {
                            li {
                                margin-bottom: 6px;
                                font-weight: $weight-500;
                                font-size: $size-14;
                                color: $color-accent;
                            }
                        }
                    }
                }
            }
            .item-box {
                overflow: hidden;
                margin: 0 auto;
                .item-head {
                    background-color: $color-light;
                    text-align: center;
                    padding: 6px 10px 6px 10px;
                    h4 {
                        font-weight: $weight-600;
                        color: $color-black-100;
                        margin: 0px;
                        outline: none;
                        line-height: normal;
                    }
                }
                .item-image {
                    background-color: $color-light;
                    height: 200px;
                    overflow: hidden;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    padding: 10px 20px 10px 20px;
                    img {
                        width: 100%;
                        object-fit: contain;
                    }
                    small {
                        font-weight: $weight-500;
                        font-size: $size-11;
                        color: $color-black-300;
                        margin: 0px;
                    }
                }
            }
        }
        .profile-box {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .actor-photo {
                margin-right: 10px;
                .photo-image {
                    width: 54px;
                    height: 54px;
                    overflow: hidden;
                    border-radius: 50%;
                    background-color: $color-white;
                    cursor: pointer;
                    img {
                        height: 100%;
                        object-fit: contain;
                    }
                }
                .photo-div {
                    width: 54px;
                    height: 54px;
                    overflow: hidden;
                    border-radius: 50%;
                    background-color: $color-white;
                    cursor: pointer;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .mat-icon {
                        width: unset;
                        height: unset;
                        font-weight: $weight-200 !important;
                        font-size: 66px;
                        color: rgba($color: $color-black, $alpha: 0.1);
                    }
                }
            }
            .actor-details {
                flex-grow: 9;
                h3 {
                    font-weight: $weight-600;
                    color: $color-black-100;
                    margin: 0px;
                }
                h4 {
                    font-weight: $weight-600;
                    color: $color-black-100;
                    margin: 0px;
                }
                span {
                    font-size: $size-12;
                    font-weight: $weight-500;
                    color: $color-black-100;
                    margin: 0px;
                }
                small {
                    font-size: $size-11;
                    font-weight: $weight-500;
                    color: $color-black-100;
                    margin: 0px;
                }
            }
        }
        .verticlemenu-box {
            .nav-list {
                margin: 0px;
                padding: 0px;
                .list-item {
                    width: 100%;
                    .mat-list-item-content {
                        padding: 16px 0px 16px 0px;
                        margin: 10px 0px 10px 0px;
                    }
                    .icon {
                        font-size: 24px;
                        color: $color-black;
                        margin-right: 10px;
                        background-color: $color-yellow-light;
                        border-radius: 10px;
                        padding: 6px;
                    }
                    span {
                        font-size: $size-14;
                        font-weight: $weight-600;
                        color: $color-black-400;
                        text-transform: capitalize;
                    }
                    &:hover {
                        background-color: rgba($color: $color-accent, $alpha: 0.06);
                        .icon {
                            color: $color-black;
                        }
                    }
                }
                .active {
                    background-color: $color-white;
                    .icon {
                        color: $color-black;
                    }
                    span {
                        color: $color-black-100;
                    }
                    &:hover {
                        background-color: rgba($color: $color-accent, $alpha: 0.06);
                    }
                }
            }
        }
        .section-box {
            .section-header {
                padding: 0px 0px 10px 0px;
                h3 {
                    font-weight: $weight-600;
                    color: $color-black-100;
                    margin: 0px;
                    line-height: normal;
                }
                .mat-icon {
                    height: unset;
                    width: unset;
                    font-size: 20px;
                    margin-right: 5px;
                }
            }
            .section-body {
                padding: 16px 0px 16px 0px;
                label {
                    font-weight: $weight-500;
                    font-size: $size-13;
                    color: $color-black-300;
                }
                span {
                    font-weight: $weight-500;
                    font-size: $size-14;
                    color: $color-black-100;
                }
                .mat-flat-button {
                    span {
                        color: $color-white !important;
                    }
                }
                a {
                    font-weight: $weight-600;
                    font-size: $size-13;
                    color: $color-primary;
                    cursor: pointer;
                    text-decoration: none;
                    outline: none;
                    &:hover {
                        text-decoration: underline;
                    }
                }
                .mat-icon {
                    font-weight: $weight-600;
                    font-size: $size-14;
                    color: $color-accent;
                    width: 20px;
                    height: 20px;
                    line-height: 20px;
                    vertical-align: middle;
                    cursor: pointer;
                }
                .infocard-box {
                    width: 280px;
                    height: 180px;
                    border: 1px solid $color-border;
                    color: $color-accent;
                    overflow: hidden;
                    display: flex;
                    justify-content: center;
                    padding: 8px 8px 8px 8px;
                    img {
                        height: 100%;
                        object-fit: contain;
                    }
                }
                .section-devider {
                    padding: 16px 0px 16px 0px;
                }
                .upload-box {
                    border: 1px solid rgba($color: $color-black, $alpha: 0.1);
                    text-align: center;
                    background-color: rgba($color: $color-black, $alpha: 0.02);
                    img {
                        width: 60px;
                        opacity: 0.1;
                    }
                    button {
                        span {
                            color: $color-white;
                        }
                    }
                }
                .filter-menu-box {
                    overflow-x: auto;
                    .mat-button-toggle-group {
                        border: none;
                        border-radius: unset;
                        .mat-button-toggle {
                            border: none;
                            .mat-button-toggle-button {
                                .mat-button-toggle-label-content {
                                    line-height: 0px;
                                    padding: 16px 16px 16px 16px;
                                    font-family: $font-name;
                                    font-size: $size-13;
                                    font-weight: $weight-600;
                                    color: $color-black-400;
                                }
                            }
                            &:hover {
                                border-bottom: 2px solid $color-primary;
                                .mat-button-toggle-label-content {
                                    color: $color-primary;
                                }
                            }
                        }
                        .mat-button-toggle-checked {
                            background-color: rgba($color: $color-primary, $alpha: 0.02);
                            border-bottom: 2px solid $color-primary;
                            .mat-button-toggle-button {
                                .mat-button-toggle-label-content {
                                    color: $color-primary;
                                }
                                &:hover {
                                    background: $color-white !important;
                                    .mat-button-toggle-label-content {
                                        background-color: $color-white !important;
                                        color: rgba($color: $color-primary, $alpha: 1);
                                    }
                                }
                            }
                        }
                    }
                }
                .bookings-box {
                    margin: 16px 0px 0px 0px;
                    .item-box {
                        margin: 16px 0px 36px 0px;
                        border-radius: 14px 14px 0px 0px;
                        @include box-shadow(0px 0px 8px 0px rgba(0, 0, 0, 0.1));
                        overflow: hidden;
                        .item-meta {
                            h3 {
                                font-weight: $weight-600;
                                color: $color-black-100;
                                margin: 0px 0px 6px 0px;
                            }
                            h5 {
                                font-weight: $weight-600;
                                font-size: $size-13;
                                color: $color-black-100;
                                margin: 10px 0px 0px 0px;
                            }
                            h6 {
                                font-weight: $weight-500;
                                color: $color-black-400;
                                font-size: $size-13;
                                margin: 12px 0px 6px 0px;
                                span {
                                    font-weight: $weight-600;
                                    color: $color-black-100;
                                    font-size: $size-13;
                                    padding-bottom: 3px;
                                    border-bottom: 1px solid $color-primary;
                                }
                            }
                            i {
                                font-weight: $weight-500;
                                color: $color-black-400;
                                font-size: $size-11;
                            }
                            table {
                                border-spacing: 0px;
                                margin: 5px 0px 5px 0px;
                                tr {
                                    td {
                                        padding: 0px 18px 0px 0px;
                                        h6 {
                                            font-weight: $weight-500;
                                            font-size: $size-13;
                                            color: $color-black-100;
                                            margin: 0px 0px 4px 0px;
                                            .mat-icon {
                                                width: unset;
                                                height: unset;
                                                vertical-align: middle;
                                                line-height: 0px;
                                                font-weight: $weight-200;
                                                font-size: $size-16;
                                                color: $color-black-400;
                                                margin-right: 3px;
                                            }
                                        }
                                    }
                                }
                            }
                            .tags-div {
                                padding: 10px 0px 0px 0px;
                                margin: 0px 0px 12px 0px;
                                .tag {
                                    font-size: $size-11;
                                }
                            }
                            .item-image {
                                height: 140px;
                                overflow: hidden;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                text-align: center;
                                padding: 0px 16px 0px 16px;
                                img {
                                    width: 100%;
                                    height: 100%;
                                    object-fit: contain;
                                }
                                small {
                                    font-weight: $weight-500;
                                    font-size: $size-11;
                                    color: $color-black-300;
                                    margin: 0px;
                                }
                            }
                            .item-price {
                                margin: 20px 0px 5px 0px;
                                strong {
                                    padding: 5px 16px 5px 16px;
                                    text-align: center;
                                    font-size: $size-18;
                                    font-weight: $weight-600;
                                    color: $color-black-100;
                                    background-color: $color-primary;
                                    border-radius: 6px 0px 0px 6px;
                                }
                            }
                        }
                        .item-dates {
                            padding: 16px 16px 6px 16px;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            background-color: $color-primary-light;
                            margin-bottom: 10px;
                            .date {
                                text-align: center;
                                h5 {
                                    font-weight: $weight-600;
                                    font-size: 20px;
                                    color: $color-black-100;
                                    margin: 0px;
                                }
                                h6 {
                                    font-weight: $weight-500;
                                    color: $color-black-400;
                                    font-size: $size-13;
                                    text-transform: uppercase;
                                    margin: 0px;
                                }
                                strong {
                                    font-weight: $weight-600;
                                    color: $color-black-100;
                                    font-size: $size-13;
                                }
                            }
                            .devider {
                                text-align: center;
                                margin: 5px 0px 5px 0px;
                                .mat-icon {
                                    color: $color-black-300;
                                    font-size: 24px;
                                }
                            }
                        }
                        .meta-section {
                            padding: 0px 0px 0px 16px;
                        }
                        .item-details {
                            .booking-details {
                                .booking-dates {
                                    strong {
                                        font-weight: $weight-600;
                                        color: $color-white;
                                        font-size: $size-13;
                                        padding: 4px 10px 4px 10px;
                                        background-color: $color-primary;
                                        border-radius: 10px;
                                    }
                                    h5 {
                                        font-weight: $weight-600;
                                        color: $color-black-100;
                                        font-size: $size-13;
                                        margin: 10px 0px 0px 0px;
                                    }
                                    h6 {
                                        font-weight: $weight-600;
                                        color: $color-black-100;
                                        font-size: $size-11;
                                        margin: 2px 0px 0px 0px;
                                        span {
                                            font-weight: $weight-600;
                                            color: $color-black-400;
                                            font-size: $size-11;
                                        }
                                    }
                                    small {
                                        font-weight: $weight-500;
                                        color: $color-black-100;
                                        font-size: $size-11;
                                    }
                                }
                                .booking-duration {
                                    h4 {
                                        font-weight: $weight-600;
                                        color: $color-black-100;
                                        font-size: $size-13;
                                        margin: 0px;
                                    }
                                    .mat-icon {
                                        color: $color-black;
                                    }
                                }
                            }
                            .fares-details {
                                background-color: $color-light;
                                padding: 6px 10px 6px 10px;
                                h4 {
                                    font-weight: $weight-600;
                                    color: $color-black-100;
                                    font-size: $size-13;
                                    margin: 0px;
                                }
                                small {
                                    font-weight: $weight-500;
                                    color: $color-black-100;
                                    font-size: $size-11;
                                    line-height: normal;
                                }
                                table {
                                    border-spacing: 0px;
                                    width: 100%;
                                    margin-top: 10px;
                                    tr {
                                        td {
                                            padding: 3px 0px 3px 0px;
                                            label {
                                                font-weight: $weight-600;
                                                color: $color-black-400;
                                                font-size: $size-11;
                                                line-height: normal;
                                                margin: 0px;
                                            }
                                            h4 {
                                                font-weight: $weight-600;
                                                color: $color-black-100;
                                                font-size: $size-11;
                                                text-align: right;
                                                line-height: normal;
                                                margin: 0px;
                                            }
                                            span {
                                                font-weight: $weight-600;
                                                color: $color-black-100;
                                                font-size: $size-13;
                                                margin: 0px;
                                                line-height: normal;
                                            }
                                            h3 {
                                                font-weight: $weight-600;
                                                color: $color-black-100;
                                                font-size: $size-13;
                                                text-align: right;
                                                margin: 0px;
                                                line-height: normal;
                                            }
                                        }
                                        .border-divider {
                                            border-top: 1px solid $color-gray;
                                            padding-top: 5px;
                                        }
                                    }
                                }
                            }
                            .fares-pay {
                                margin: 10px 0px 0px 0px;
                                button {
                                    span {
                                        color: $color-white;
                                    }
                                }
                            }
                            .instruction-box {
                                padding: 0px 0px 16px 0px;
                                h3 {
                                    margin: 0px 0px 10px 0px;
                                    font-weight: $weight-500;
                                    color: $color-black-100;
                                }
                                .instructions {
                                    ul {
                                        margin: 0px;
                                        padding-inline-start: 16px;
                                        li {
                                            padding: 0px;
                                            font-size: $size-11;
                                            font-weight: $weight-600;
                                            color: $color-black-300;
                                        }
                                    }
                                }
                            }
                        }
                        .mat-accordion {
                            .mat-expansion-panel {
                                box-shadow: none;
                                border-radius: 0px;
                                border-bottom: 2px solid $color-primary;
                                .mat-expansion-panel-header {
                                    padding: 0px 16px 0px 16px;
                                    background-color: $color-primary-light;
                                    .mat-content {
                                        font-size: $size-13;
                                        font-weight: $weight-600;
                                        color: $color-black-100;
                                    }
                                    .mat-expansion-panel-header-description {
                                        justify-content: space-between;
                                        align-items: center;
                                        margin: 0px !important;
                                        .mat-icon {
                                            color: $color-black-100;
                                            font-weight: $weight-600;
                                            font-size: $size-18;
                                        }
                                    }
                                    &:hover {
                                        background: $color-primary-light;
                                    }
                                }
                                .mat-expansion-panel-content {
                                    font-size: $size-13;
                                    font-weight: $weight-500;
                                    color: $color-black-100;
                                    .mat-expansion-panel-body {
                                        padding: 0 10px 16px 10px;
                                    }
                                }
                            }
                            .mat-expanded {
                                margin: 0px;
                                .mat-expansion-panel-header {
                                    background: $color-white;
                                }
                                .mat-expansion-panel-content {
                                    background: $color-white;
                                }
                            }
                        }
                        .support-field {
                            margin: 0 12px 0 12px;
                            cursor: pointer;
                            span {
                                color: $color-accent;
                                font-size: $size-12;
                                font-weight: $weight-500;
                            }
                        }
                    }
                }
                .orders-box {
                    margin: 16px 0px 0px 0px;
                    .item-box {
                        margin: 16px 0px 36px 0px;
                        border-radius: 14px 14px 0px 0px;
                        @include box-shadow(0px 0px 8px 0px rgba(0, 0, 0, 0.1));
                        overflow: hidden;
                        border-bottom: 2px solid $color-primary;
                        .item-meta {
                            .item-notes {
                                margin: 20px 0px 5px 0px;
                                small {
                                    padding: 5px 16px 5px 16px;
                                    font-size: $size-11;
                                    font-weight: $weight-500;
                                    color: $color-black-200;
                                }
                            }
                            .item-price {
                                margin: 20px 0px 5px 0px;
                                strong {
                                    padding: 5px 16px 5px 16px;
                                    text-align: center;
                                    font-size: $size-18;
                                    font-weight: $weight-600;
                                    color: $color-black-100;
                                }
                            }
                        }
                        .item-dates {
                            padding: 16px 16px 16px 16px;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            flex-wrap: wrap;
                            background-color: $color-primary-light;
                            margin-bottom: 10px;
                            .date {
                                h3 {
                                    font-weight: $weight-600;
                                    color: $color-black-100;
                                    margin: 0px;
                                }
                                strong {
                                    font-weight: $weight-600;
                                    color: $color-black-100;
                                    font-size: $size-13;
                                }
                            }
                        }
                        .meta-section {
                            padding: 0px 16px 0px 16px;
                        }
                    }
                }
            }
        }
    }
    .summary-box {
        margin: 0px 0px 0px 0px;
        padding: 26px;
        border-radius: 10px;
        overflow: hidden;
        @include box-shadow(0px 0px 8px 0px rgba(0, 0, 0, 0.1));
        .vehicle-details {
            .item-black-head {
                background-color: $color-accent;
                text-align: center;
                padding: 6px 10px 6px 10px;
                h4 {
                    font-weight: $weight-600;
                    color: $color-white-100;
                    margin: 0px;
                    outline: none;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    display: block;
                    overflow: hidden;
                    line-height: normal;
                }
            }
            .item-image {
                height: 140px;
                margin-bottom: 16px;
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: flex-start;
                text-align: center;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    vertical-align: top;
                }
                small {
                    font-weight: $weight-500;
                    font-size: $size-11;
                    color: $color-black-300;
                    margin: 0px;
                }
            }
            .item-meta {
                padding: 0px 16px 0px 0px;
                h3 {
                    font-weight: $weight-600;
                    color: $color-black-100;
                    margin: 0px;
                    line-height: normal;
                }
                h5 {
                    font-weight: $weight-600;
                    font-size: $size-13;
                    color: $color-black-100;
                    margin: 0px;
                    line-height: normal;
                }
                h6 {
                    font-weight: $weight-500;
                    color: $color-black-400;
                    font-size: $size-13;
                    margin: 10px 0px 5px 0px;
                    line-height: normal;
                }
                i {
                    font-weight: $weight-500;
                    color: $color-black-400;
                    font-size: $size-11;
                }
                table {
                    border-spacing: 0px;
                    margin: 5px 0px 5px 0px;
                    tr {
                        td {
                            padding: 0px 18px 0px 0px;
                            h6 {
                                font-weight: $weight-500;
                                font-size: $size-13;
                                color: $color-black-100;
                                .mat-icon {
                                    width: unset;
                                    height: unset;
                                    vertical-align: middle;
                                    line-height: 0px;
                                    font-weight: $weight-200;
                                    font-size: $size-16;
                                    color: $color-black-400;
                                    margin-right: 3px;
                                }
                            }
                        }
                    }
                }
            }
        }
        .service-box {
            .section-2 {
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                .icon {
                    margin-right: 10px;
                    img {
                        width: 34px;
                        object-fit: cover;
                    }
                }
            }
        }
        .info-box {
            ul {
                list-style-type: none;
                margin: 0px 0px 8px 0px;
                padding: 0px;
                li {
                    font-weight: $weight-500;
                    color: $color-black-100;
                    font-size: $size-13;
                    padding: 0px 0px 10px 0px;
                    .protect-icon {
                        margin-right: 8px;
                        vertical-align: middle;
                        width: 15px;
                    }
                }
            }
            i {
                font-weight: $weight-500;
                color: $color-black-300;
                font-size: $size-13;
                a {
                    outline: none;
                    text-decoration: underline;
                    color: $color-blue;
                }
            }
        }
    }
    .fares-box {
        margin: 0px 0px 0px 0px;
        padding-bottom: 10px;
        border-radius: 10px;
        @include box-shadow(0px 0px 8px 0px rgba(0, 0, 0, 0.1));
        .fares {
            padding: 26px 26px 26px 26px;
            h4 {
                font-size: $size-15;
                font-weight: $weight-600;
                color: $color-black-100;
                margin: 0px;
            }
            h4.green-text {
                color: $color-green;
            }
            small {
                font-size: $size-11;
                font-weight: $weight-500;
                color: $color-black-300;
            }
            span {
                font-size: $size-12;
                font-weight: $weight-500;
                color: $color-black-300;
            }
            table {
                width: 100%;
                margin: 10px 0px 0px 0px;
                border-spacing: 0px;
                tr {
                    td {
                        padding: 5px 0px 5px 0px;
                        label {
                            font-size: $size-13;
                            font-weight: $weight-500;
                            color: $color-black-100;
                            line-height: normal;
                        }
                        h4 {
                            font-size: $size-14;
                            font-weight: $weight-500;
                            color: $color-black-100;
                            margin: 0px;
                            line-height: normal;
                            b {
                                font-weight: $weight-600;
                            }
                        }
                        span {
                            font-size: $size-14;
                            font-weight: $weight-600;
                            color: $color-black-100;
                            margin: 0px;
                            line-height: normal;
                        }
                        h3 {
                            font-size: $size-15;
                            font-weight: $weight-500;
                            color: $color-black-100;
                            margin: 0px;
                            line-height: normal;
                        }
                    }
                    .border-divider {
                        padding: 14px 0px 0px 0px;
                        border-top: 1px solid $color-black;
                    }
                }
            }
        }
        .extra-box {
            margin: 0px 6px 0px 6px;
            padding: 10px 20px 10px 20px;
            background-color: $color-yellow-light;
            span {
                font-size: $size-13;
                font-weight: $weight-600;
                color: $color-black-100;
            }
            i {
                font-weight: $weight-500;
                color: $color-black-300;
                font-size: $size-11;
            }
        }
        .notes-box {
            padding: 10px 26px 0px 26px;
            small {
                font-size: $size-11;
                font-weight: $weight-500;
                color: $color-black-200;
            }
            ul {
                padding: 0px 16px 0px 16px;
                margin: 0px;
                li {
                    font-size: $size-11;
                    font-weight: $weight-500;
                    color: $color-black-300;
                }
            }
        }
    }
    .accept-box {
        margin: 10px 0px 0px 0px;
        padding: 26px;
        border-radius: 10px;
        @include box-shadow(0px 0px 8px 0px rgba(0, 0, 0, 0.1));
        small {
            font-size: $size-12;
            font-weight: $weight-500;
            color: $color-black-400;
            text-align: justify;
        }
        a {
            font-size: $size-12;
            font-weight: $weight-600;
            color: $color-black-100;
            text-decoration: underline;
            outline: none;
        }
    }
}

.timeline {
    position: relative;
    list-style: none;
    text-align: left;
    max-width: 100%;
    margin-left: 6px;
    margin-bottom: -16px;
    padding: 0px;
    .event {
        position: relative;
        border-left: 3px dashed $color-border;
        .event-container {
            padding: 0px 0px 20px 20px;
            .event-container-body {
                padding: 0px 16px 0px 16px;
                .section-2 {
                    min-width: 0px;
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-between;
                    .meta {
                        width: 90%;
                        .section-3 {
                            border-bottom: 1px solid $color-border;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            padding-bottom: 6px;
                            margin-bottom: 6px;
                            h4 {
                                font-weight: $weight-600;
                                color: $color-black-100;
                                margin: 0px;
                                line-height: normal;
                                flex: 0.3;
                            }
                            .mat-icon {
                                flex: 0.2;
                                color: $color-black-300;
                            }
                            span {
                                font-weight: $weight-600;
                                color: $color-black-200;
                                font-size: $size-13;
                                flex: 0.5;
                                text-align: right;
                            }
                        }
                        h4 {
                            font-weight: $weight-600;
                            color: $color-red;
                            margin: 0px;
                            line-height: normal;
                        }
                        small {
                            font-weight: $weight-500;
                            color: $color-black-400;
                            font-size: $size-11;
                        }
                        a {
                            outline: none;
                            text-decoration: none;
                        }
                    }
                    .action {
                        .mat-icon {
                            color: $color-blue;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
        &:last-of-type {
            padding-bottom: 0;
            margin-bottom: 0;
            border-left: 3px solid $color-white;
        }
        &:before,
        &:after {
            position: absolute;
            display: block;
            top: 0;
        }
        &:before {
            left: -150px;
            color: $color-accent;
            font-weight: $weight-400;
            font-size: $size-13;
            content: attr(data-date);
            text-align: right;
            min-width: 120px;
        }
        &:after {
            left: -8px;
            background: $color-white;
            border: 2px solid $color-accent;
            border-radius: 50%;
            height: 8px;
            width: 8px;
            content: "";
            top: 0px;
        }
    }
    .green {
        &:after {
            border-color: $color-green;
        }
    }
    .red {
        &:after {
            border-color: $color-red;
        }
    }
}

.empty-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20% 16px 30px 16px;
    color: $color-black-100;
    font-size: $size-13;
    font-weight: $weight-500;
    h5 {
        color: $color-black-100;
        font-size: $size-13;
        font-weight: $weight-500;
    }
}
.empty-data {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 1px dashed rgba($color: $color-primary, $alpha: 0.6);
    padding: 36px 16px 36px 16px;
    border-radius: 4px;
    .mat-icon {
        width: unset;
        height: unset;
        line-height: unset;
        color: $color-accent;
    }
    h3 {
        margin: 10px 0px 10px 0px;
        font-weight: $weight-600;
        color: $color-accent;
    }
    p {
        font-size: $size-13;
        font-weight: $weight-400;
        color: $color-accent;
        line-height: 24px;
    }
}

// Card accordion
.card-accordion {
    .mat-accordion {
        .mat-expansion-panel {
            box-shadow: none !important;
            background-color: transparent !important;
            border-radius: 10px !important;
            margin-bottom: 16px;
            .mat-expansion-panel-header {
                padding: 0px 16px 0px 16px;
                background-color: $color-accent;
                .mat-expansion-panel-header-title {
                    color: $color-white !important;
                    font-weight: $weight-600 !important;
                    font-size: $size-15;
                }
                .mat-expansion-panel-header-description {
                    justify-content: flex-end;
                    align-items: center;
                    margin: 0px !important;
                    .mat-icon {
                        color: $color-white !important;
                        font-weight: $weight-600;
                        font-size: $size-18;
                    }
                }
                .mat-expansion-indicator::after {
                    color: $color-white !important;
                }
                &:hover {
                    background-color: $color-accent !important;
                }
            }
            .mat-expanded {
                height: 48px !important;
                background-color: $color-accent !important;
                .mat-expansion-panel-header-title {
                    color: $color-black-100;
                    font-weight: $weight-600;
                }
                &:hover {
                    background-color: $color-accent !important;
                }
            }
            .mat-expansion-panel-content {
                .mat-expansion-panel-body {
                    padding: 16px 0px 0px 0px;
                    p {
                        font-size: $size-13;
                        color: $color-black-200;
                        font-weight: $weight-500;
                        line-height: 26px;
                    }
                }
            }
        }
    }
}

.stepper {
    .tagline {
        padding: 0px 0px 16px 0px;
        h3 {
            font-weight: $weight-700;
            color: $color-black-100;
            margin: 0px;
            span {
                color: $color-black-400;
                font-weight: $weight-600;
                strong {
                    color: $color-primary;
                }
            }
        }
    }
    .mat-stepper-horizontal {
        .mat-horizontal-stepper-header-container {
            margin: 0 auto;
            padding: 0px;
            margin-bottom: 6px;
            .mat-horizontal-stepper-header {
                padding: 16px;
                border: 1px dashed $color-primary;
                min-width: 180px;
                &::before {
                    border-top-style: none;
                }
                &::after {
                    border-top-style: none;
                }
                .mat-step-icon-state-number {
                    font-size: $size-13;
                    font-weight: $weight-700;
                    color: $color-white;
                }
                .mat-step-icon {
                    width: 30px;
                    height: 30px;
                    border-radius: 1px;
                    color: $color-white;
                    background-color: $color-black;
                }
                .mat-step-icon-selected {
                    background-color: $color-primary;
                }
                .mat-step-label {
                    font-size: $size-13;
                    font-weight: $weight-600;
                }
                &:hover {
                    background-color: transparent;
                }
                &:active {
                    background-color: transparent;
                }
                &:focus {
                    background-color: transparent;
                }
            }
            .mat-stepper-horizontal-line {
                top: 44px;
                border: 2px solid rgba($color: $color-primary, $alpha: 0.2);
            }
        }
        .mat-horizontal-content-container {
            padding: 0px;
            .stepper-body {
                padding-top: 16px;
                .image-uploader-round-bigger {
                    margin: 0 auto;
                }
            }
            .stepper-footer {
                padding: 16px 0px 0px 0px;
            }
        }
    }
}

.slick-slider {
    .slick-arrow {
        height: 46px !important;
        width: 46px !important;
        bottom: unset;
        top: calc(100% + 10px);
        transform: unset;
    }
    .slick-prev {
        left: unset;
        right: 30px;
        margin-right: 8px;
        &::before {
            color: $color-black-300 !important;
            content: "\279C";
            display: inline-block;
            transform: scale(-1, 1);
        }
    }
    .slick-next {
        right: 0;
        &::before {
            color: $color-black-300 !important;
            content: "\279C";
            display: inline-block;
        }
    }
    .slick-slide {
        margin-right: 26px;
    }
    .slick-track {
        padding-left: 8px;
        padding-top: 8px;
        padding-bottom: 8px;
        margin: auto !important;
    }
}

.custom-pagination {
    background-color: $color-white;
    @include box-shadow(0px 0px 8px 0px rgba(0, 0, 0, 0.1));
    border-radius: 10px;
    overflow: hidden;
    margin: 0px 0px 16px 16px;
    .mat-paginator {
        width: 100%;
        .mat-paginator-outer-container {
            .mat-paginator-container {
                .mat-paginator-page-size {
                    .mat-paginator-page-size-label {
                        font-weight: $weight-600;
                        font-size: $size-11;
                        color: $color-black-100;
                    }
                    .mat-paginator-page-size-select {
                        font-weight: $weight-600;
                        font-size: $size-13;
                        color: $color-black-100;
                    }
                }
                .mat-paginator-range-actions {
                    .mat-paginator-range-label {
                        font-weight: $weight-600;
                        font-size: $size-13;
                        color: $color-black-100;
                    }
                    .mat-icon-button {
                        .mat-paginator-icon {
                            color: $color-border;
                        }
                    }
                    .mat-icon-button:not([disabled]) {
                        .mat-paginator-icon {
                            color: $color-accent;
                        }
                    }
                }
            }
        }
    }
}

.document {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: $weight-600;
        color: $color-black-100;
        line-height: normal;
        margin: 0px 0px 4px 0px;
        b {
            font-weight: $weight-600;
        }
    }
    p {
        font-size: $size-14;
        font-weight: $weight-400;
        color: $color-black-100;
        line-height: 24px;
        b {
            font-weight: $weight-600;
        }
        strong {
            font-weight: $weight-600;
        }
        a {
            font-size: $size-14;
            font-weight: $weight-500;
            color: $color-blue;
            outline: none;
            text-decoration: none;
        }
    }
    ol,
    ul {
        padding: 0px 0px 0px 16px;
        margin: 0px;
        li {
            padding: 0px 0px 0px 0px;
            margin: 8px 0px 8px 0px;
            h3 {
                font-size: $size-14;
                font-weight: $weight-600;
                color: $color-black-100;
                line-height: normal;
                margin: 0px 0px 4px 0px;
                text-transform: uppercase;
            }
            font-size: $size-14;
            font-weight: $weight-400;
            color: $color-black-100;
            line-height: 24px;
            b {
                font-weight: $weight-600;
            }
            a {
                font-size: $size-14;
                font-weight: $weight-500;
                color: $color-blue;
                outline: none;
                text-decoration: none;
            }
        }
    }
    small {
        font-size: $size-11;
        font-weight: $weight-400;
        color: $color-black;
        b {
            font-weight: $weight-600;
        }
    }
}

.horizonatal-image-gallery {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 10px 0px 0px 0px;
    .gallery-item {
        margin: 0px 6px 6px 0px;
        .item {
            width: 80px;
            height: 80px;
            overflow: hidden;
            cursor: pointer;
            border: 1px solid $color-border;
            border-radius: 4px;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}

.types-selection {
    .mat-button-toggle-group {
        border-radius: 30px;
        border: 2px solid $color-primary !important;
        margin-top: 4px;
        .mat-button-toggle {
            background-color: $color-white !important;
            .mat-button-toggle-label-content {
                padding: 0px 12px 0px 12px;
                font-size: $size-14;
                font-weight: $weight-600;
                color: $color-black-100;
                vertical-align: middle;
                .mat-icon {
                    width: unset;
                    height: unset;
                    line-height: unset;
                    vertical-align: top;
                    font-size: 22px;
                    margin-right: 2px;
                }
            }
        }
        .mat-button-toggle-checked {
            background-color: $color-primary !important;
            .mat-button-toggle-label-content {
                color: $color-black-100;
            }
        }
    }
}

.vehicle-card {
    border: 1px solid $color-border;
    background-color: $color-white;
    border-radius: 4px;
    margin: 0px 0px 0px 0px;
    padding: 16px 16px 16px 16px;
    .booking-details {
        border-top: 1px solid $color-border;
        padding: 10px 0px 0px 0px;
        margin: 0px 0px 0px 0px;
        .booking-meta {
            h4 {
                color: $color-black-200;
                font-weight: $weight-500;
                margin: 0px 0px 8px 0px;
            }
            .date {
                display: flex;
                align-items: center;
                .mat-display-1 {
                    color: $color-black-100;
                    font-weight: $weight-500;
                    margin: 0px 10px 0px 0px;
                }
                h3 {
                    color: $color-black-100;
                    font-weight: $weight-500;
                    margin: 0px 0px 0px 0px;
                }
                h4 {
                    color: $color-black-100;
                    font-weight: $weight-500;
                    margin: 0px 0px 0px 0px;
                }
            }
            .address {
                h4 {
                    color: $color-black-100;
                    font-weight: $weight-500;
                    margin: 0px 0px 0px 0px;
                }
                h5 {
                    color: $color-black-300;
                    font-weight: $weight-500;
                    margin: 0px 0px 0px 0px;
                }
            }
        }
        .pickupdrop-duration {
            width: 100px;
            border: 1px solid $color-border;
            border-radius: 50px;
            padding: 10px;
            label {
                font-weight: $weight-500;
                font-size: $size-13;
                color: $color-black-200;
            }
            h3 {
                color: $color-black-100;
                font-weight: $weight-500;
                margin: 0px 0px 0px 0px;
            }
        }
    }
}

.fare-card {
    border: 1px solid $color-border;
    background-color: $color-white;
    border-radius: 4px;
    margin: 0px 0px 0px 0px;
    padding: 16px 16px 16px 16px;
    .fare-details {
        h4 {
            color: $color-black-100;
            font-weight: $weight-500;
            margin: 0px 0px 0px 0px;
            line-height: normal;
        }
        small {
            color: $color-black-100;
            font-weight: $weight-400;
            font-size: $size-11;
            margin: 0px 0px 0px 0px;
            line-height: normal;
        }
        .fare {
            display: flex;
            justify-content: space-between;
            align-items: center;
            label {
                color: $color-black-100;
                font-size: $size-13;
                font-weight: $weight-400;
                margin: 0px 0px 8px 0px;
            }
            h4 {
                color: $color-black-100;
                font-weight: $weight-500;
                margin: 0px 0px 8px 0px;
            }
        }
        .total {
            margin: 0px 0px 0px 0px;
            padding: 6px 0px 0px 0px;
            border-top: 1px solid $color-border;
            label {
                font-weight: $weight-500;
            }
        }
    }
}

.mat-dialog-container {
    border-radius: 10px !important;
    padding: 0px 0px 0px 0px !important;
}
.modal {
    padding: 0px;
    .modal-header {
        border-bottom: 1px solid $color-gray;
        margin: 24px 24px 0px 24px;
        .modal-title {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-bottom: 4px;
            h3 {
                font-size: $size-18;
                font-weight: $weight-600;
                margin: 0px 0px 0px 0px;
                vertical-align: bottom;
            }
            .div-spacer {
                flex: 1 1 auto;
            }
            .mat-icon-button {
                width: 34px;
                height: 34px;
                line-height: unset;
                .mat-icon {
                    color: $color-accent;
                    font-size: 20px;
                    font-weight: $weight-700;
                }
            }
        }
    }
    .modal-body {
        padding: 10px 22px 10px 24px;
        margin-right: 2px;
        .modal-message {
            margin-top: 16px;
            h3 {
                font-weight: $weight-600;
                color: $color-black-100;
                line-height: normal;
            }
            h4 {
                font-weight: $weight-500;
                color: $color-black-100;
                line-height: normal;
                margin: 0px;
                span {
                    color: $color-blue;
                }
            }
            h5 {
                font-size: $size-14;
                font-weight: $weight-500;
                color: $color-black-100;
                line-height: normal;
                b {
                    font-weight: $weight-500;
                    color: $color-blue;
                }
            }
            small {
                font-size: $size-11;
                font-weight: $weight-500;
                color: $color-black-300;
                line-height: normal;
                i {
                    font-weight: $weight-500;
                    color: $color-black-300;
                }
            }
            a {
                text-decoration: underline;
                font-size: $size-14;
                font-weight: $weight-500;
                color: $color-blue;
                outline: none;
                cursor: pointer;
            }
        }
        .instructions {
            ul {
                margin: 0px;
                padding-inline-start: 16px;
                li {
                    padding: 0px;
                    font-size: $size-11;
                    font-weight: $weight-600;
                    color: $color-black-300;
                }
            }
        }
    }
    .modal-footer {
        text-align: right;
        padding: 4px 24px 24px 24px;
        .access {
            .or-section {
                h5 {
                    font-size: $size-14;
                    font-weight: $weight-500;
                    color: $color-black-400;
                    margin: 0px 0px 0px 0x;
                    padding-top: 10px;
                }
            }
            a {
                font-size: $size-13;
                font-weight: $weight-600;
                color: $color-accent;
                cursor: pointer;
                text-decoration: none;
                outline: none;
                line-height: normal;
                &:hover {
                    color: $color-accent;
                    text-decoration: underline;
                }
            }
            p {
                margin: 10px 0px 0px 0px;
                font-size: $size-13;
                font-weight: $weight-600;
                color: $color-black-100;
                line-height: normal;
            }
            .green-underline-regular {
                margin: 0 auto;
                opacity: 0.4;
            }
        }
        button {
            margin: 0px 0px 0px 5px;
        }
    }
}

.modal-body-scroller {
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
}
.modal-body-scroller::-webkit-scrollbar {
    width: 10px;
    position: absolute;
    top: 0px;
}
.modal-body-scroller::-webkit-scrollbar-track {
    background: $color-white;
}
.modal-body-scroller::-webkit-scrollbar-thumb {
    background: rgba($color: $color-accent, $alpha: 0.1);
    border-radius: 30px;
}
.modal-body-scroller::-webkit-scrollbar-thumb:hover {
    background: rgba($color: $color-accent, $alpha: 0.2);
}

.custom-loader {
    position: absolute;
    width: 100%;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 9;
    background-color: rgba($color: $color-white, $alpha: 0.9);
    .loader-text {
        position: sticky;
        top: 80px;
        display: flex;
        width: 100%;
        padding-top: 14%;
        justify-content: center;
        align-items: center;
        h4 {
            font-weight: $weight-600;
            color: $color-black-100;
            text-transform: uppercase;
            margin: 0px 0px 0px 10px;
        }
        .mat-spinner {
            circle {
                stroke: $color-primary;
            }
        }
    }
}

.clipboard-input {
    margin: 0 26px 0 16px;
    .mat-form-field {
        background-color: $color-white;
        border-radius: 40px;
        padding-top: 10px;
        .mat-form-field-underline {
            display: none;
        }
        .mat-input-element:disabled {
            margin-left: 30px;
            color: $color-black;
        }
        .mat-icon-button {
            margin-right: 65px;
            border-radius: 40px !important;
            .mat-button-wrapper {
                font-size: 16px;
                color: $color-blue;
            }
        }
    }
}

.coupon-button {
    .mat-flat-button {
        width: 90% !important;
        min-width: unset !important;
        background-color: rgba($color-black-400, 0.2);
        color: $color-black-300;
        padding: 10px;
        .mat-button-wrapper {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
}

.copy-to-clipboard {
    .mat-button {
        .mat-button-focus-overlay {
            background-color: transparent !important;
            opacity: 0 !important;
        }
    }
}

.filter-pannel {
    .mat-expansion-panel {
        box-shadow: none !important;
        .mat-expansion-panel-header {
            background-color: $color-yellow-light;
        }
    }
}

.booking-toggle-btn {
    padding: 12px;
    .mat-button-toggle-group {
        border: none !important;
        border-radius: unset;
        width: 80%;
        .mat-button-toggle {
            border: none !important;
            border-radius: 6px;
            .mat-button-toggle-label-content {
                font-weight: $weight-600;
                font-size: $size-13;
                text-align: left;
                margin-left: 16px;
            }
        }
        .mat-button-toggle-checked {
            background-color: $color-primary;
        }
        .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
            line-height: 42px;
        }
    }
}

.coupon-button {
    background-color: $color-primary-light;
    padding: 10px 20px 10px 20px;
    margin: 0px -20px 0px -20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    h4 {
        font-size: $size-13 !important;
        font-weight: $weight-600 !important;
        color: $color-red !important;
        margin: 0px 0px 0px 0px;
        .mat-icon {
            font-size: 20px;
            vertical-align: middle;
            margin-right: 4px;
            width: unset;
            height: unset;
            line-height: unset;
            color: $color-red !important;
        }
    }
}

.vertical-custom-toggle {
    overflow-x: auto;
    .mat-button-toggle-group {
        border: none !important;
        border-radius: unset;
        width: 100%;
        .mat-button-toggle {
            border: none !important;
            outline: none !important;
            .mat-button-toggle-button {
                .mat-button-toggle-label-content {
                    line-height: 0px;
                    padding: 12px 0px 12px 0px;
                    font-size: $size-14;
                    font-weight: $weight-600;
                    color: $color-black-400;
                    text-align: left;
                    background-color: $color-white;
                }
            }
            .mat-icon {
                height: unset;
                width: unset;
                font-size: 24px;
                color: $color-black;
                margin-right: 10px;
                background-color: $color-yellow-light;
                border-radius: 10px;
                padding: 6px;
            }
        }
        .mat-button-toggle-checked {
            background-color: $color-white;
            .mat-button-toggle-button {
                .mat-button-toggle-label-content {
                    color: $color-black-100;
                }
            }
        }
    }
}

.blinker {
    padding: 0px;
    margin: 0px 10px 0px 0px;
    place-content: center flex-start;
    box-sizing: border-box;
    display: inline-block;
    -webkit-box-pack: start;
    position: relative;
    inset: unset;
    border: unset;
    cursor: unset;
    transform: unset;
    flex: 0 0 auto;
    word-break: unset;
    box-shadow: unset;
    overflow: unset;
    -webkit-box-align: center;
    align-items: center;
    flex-flow: row nowrap;
    background: unset;
    max-width: unset;
    min-width: unset;
    z-index: unset;
    border-radius: unset;
    transition: initial;
    min-height: unset;
    max-height: unset;
    .blink-base {
        position: absolute;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        animation-duration: 1.6s;
        animation-name: blink-animation;
        animation-iteration-count: infinite;
    }
    .blink-animation {
        border-radius: 50%;
        padding: 0px;
        margin: 0px;
        position: unset;
        inset: unset;
        text-align: unset;
        border-width: initial;
        border-style: none;
        border-color: initial;
        backdrop-filter: unset;
        z-index: 0;
        box-shadow: unset;
        -webkit-box-flex: unset;
        flex-grow: unset;
        color: unset;
        outline: unset;
        width: 10px;
        height: 10px;
        cursor: unset;
        align-self: unset;
        transform: unset;
        transition: unset;
    }
    .ongoing-blink {
        background-color: #7393f0;
    }
}
@keyframes blink-animation {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    100% {
        transform: scale(2.5);
        opacity: 0.1;
    }
}

.custom-panel {
    margin-top: 24px !important;
}

.custom-carret {
    color: $color-black-300;
    font-size: 24px !important;
}

// Sliders
.slider-carousel {
    .slick-slide {
        height: 520px;
        margin-right: 0px !important;
    }
    .slide {
        overflow: hidden;
        .slide-image {
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }
    .slick-arrow {
        bottom: unset;
        top: 52% !important;
    }
    .slick-prev {
        width: 36px !important;
        height: 36px !important;
        border-radius: 50% !important;
        background-color: $color-white !important;
        z-index: 100 !important;
        margin-left: 56px;
        right: unset !important;
        left: 1% !important;
        @include box-shadow(0px 0px 8px 0px rgba(0, 0, 0, 0.2));
        &::before {
            color: $color-black-100 !important;
            content: "\279C";
            transform: scale(-1, 1);
            display: inline-block;
            font-size: 18px;
        }
        &:hover {
            background-color: $color-white;
        }
    }
    .slick-next {
        width: 36px !important;
        height: 36px !important;
        border-radius: 50% !important;
        background-color: $color-white !important;
        z-index: 100 !important;
        margin-right: 56px;
        left: unset !important;
        right: 1% !important;
        @include box-shadow(0px 0px 8px 0px rgba(0, 0, 0, 0.2));
        &::before {
            color: $color-black-100 !important;
            content: "\279C";
            display: inline-block;
            font-size: 18px;
        }
        &:hover {
            background-color: $color-white;
        }
    }
    .slick-track {
        margin: auto !important;
        padding-top: 66px;
    }
}
.slider-carousel-mobile {
    .slick-slide {
        height: 280px;
    }
    .slide {
        .slide-image {
            img {
                object-fit: cover;
            }
        }
    }
}

.offers-carousel {
    .slick-arrow {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 36px !important;
        width: 36px !important;
        bottom: unset;
        top: 42%;
        @include box-shadow(0px 0px 8px 0px rgba(0, 0, 0, 0.2));
        background-color: $color-white;
        border-radius: 50%;
        transform: unset;
        vertical-align: middle;
        &:hover {
            background-color: $color-white;
        }
        &:focus {
            background-color: $color-white;
        }
        &:active {
            background-color: $color-white;
        }
    }
    .slick-prev {
        left: 0px;
        margin-right: 8px;
        z-index: 999;
        &::before {
            color: $color-black-100 !important;
            content: "\279C";
            display: inline-block;
            transform: scale(-1, 1);
        }
    }
    .slick-next {
        right: 0px;
        z-index: 999;
        &::before {
            color: $color-black-100 !important;
            content: "\279C";
            display: inline-block;
        }
    }
    .slick-slide {
        margin-right: 26px;
    }
    .slick-track {
        padding-left: 8px;
        padding-top: 8px;
        padding-bottom: 8px;
        margin: auto !important;
    }
    .slick-disabled {
        display: none !important;
    }
}

.testimonials-carousel {
    .slick-dots li button::before {
        font-size: 14px;
        bottom: unset;
        top: calc(100% + 20px);
    }
    .slick-arrow {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 36px !important;
        width: 36px !important;
        bottom: unset;
        top: 42%;
        @include box-shadow(0px 0px 8px 0px rgba(0, 0, 0, 0.2));
        background-color: $color-white;
        border-radius: 50%;
        transform: unset;
        vertical-align: middle;
        &:hover {
            background-color: $color-white;
        }
        &:focus {
            background-color: $color-white;
        }
        &:active {
            background-color: $color-white;
        }
    }
    .slick-prev {
        left: 0px;
        margin-right: 8px;
        z-index: 999;
        &::before {
            color: $color-black-100 !important;
            content: "\279C";
            display: inline-block;
            transform: scale(-1, 1);
        }
    }
    .slick-next {
        right: 0px;
        z-index: 999;
        &::before {
            color: $color-black-100 !important;
            content: "\279C";
            display: inline-block;
        }
    }
    .slick-slide {
        margin-right: 26px;
    }
    .slick-track {
        padding-left: 8px;
        padding-top: 8px;
        padding-bottom: 8px;
        margin: auto !important;
    }
    .slick-disabled {
        display: none !important;
    }
}

.partners-carousel {
    .slick-dots li button::before {
        font-size: 14px;
        bottom: unset;
        top: calc(100% + 20px);
    }
    .slick-arrow {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 36px !important;
        width: 36px !important;
        bottom: unset;
        top: 42%;
        @include box-shadow(0px 0px 8px 0px rgba(0, 0, 0, 0.2));
        background-color: $color-white;
        border-radius: 50%;
        transform: unset;
        vertical-align: middle;
        &:hover {
            background-color: $color-white;
        }
        &:focus {
            background-color: $color-white;
        }
        &:active {
            background-color: $color-white;
        }
    }
    .slick-prev {
        left: 0px;
        margin-right: 8px;
        z-index: 999;
        &::before {
            color: $color-black-100 !important;
            content: "\279C";
            display: inline-block;
            transform: scale(-1, 1);
        }
    }
    .slick-next {
        right: 0px;
        z-index: 999;
        &::before {
            color: $color-black-100 !important;
            content: "\279C";
            display: inline-block;
        }
    }
    .slick-slide {
        margin-right: 26px;
    }
    .slick-track {
        padding-left: 8px;
        padding-top: 8px;
        padding-bottom: 8px;
        margin: auto !important;
    }
    .slick-disabled {
        display: none !important;
    }
}

.call-button {
    height: unset !important;
    width: unset !important;
    line-height: unset !important;
    vertical-align: middle !important;
    border-radius: 50% !important;
    margin-right: 4px !important;
    .mat-icon {
        font-size: 12px !important;
        padding: 2px !important;
        color: $color-accent !important;
        border-radius: 50% !important;
        background-color: $color-primary !important;
        &:hover {
            color: $color-white !important;
            background-color: $color-accent !important;
        }
    }
}
