@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import "~@angular/material/theming";
$custom-typography: mat-typography-config(
    $font-family: "Poppins",
);

$my-own-theme: (
    500: #ffc815,
    contrast: (
        500: #000000,
    ),
);

$my-own-black: (
    500: #000000,
    contrast: (
        500: #ffc815,
    ),
);

$my-own-red: (
    500: #dc262a,
    contrast: (
        500: #ffffff,
    ),
);

@include mat-core($custom-typography);
$my-app-primary: mat-palette($my-own-theme, 500);
$my-app-accent: mat-palette($my-own-black, 500);
$my-app-warn: mat-palette($my-own-red);
$my-app-theme: mat-light-theme($my-app-primary, $my-app-accent, $my-app-warn);
@include angular-material-theme($my-app-theme);

// Variables
$font-name: "Poppins", sans-serif;

$color-black: #000000;
$color-white: #ffffff;
$color-primary: #ffc815;
$color-primary-light: #fbf9f4;
$color-secondary: #808285;
$color-accent: #585858;
$color-border: #e0dbdb;
$color-gray: #8b8b8b;
$color-light: #f9f9f9;
$color-yellow-light: #ffeeb8;

$color-black-100: #000000;
$color-black-200: #434246;
$color-black-300: #6d6b72;
$color-black-400: #8b8b8b;
$color-white-100: #ffffff;
$color-white-200: #faf9fc;
$color-white-300: #ebe9f0;

$color-danger: #f44336;
$color-warning: #ffa200;
$color-success: #00db00;

$color-green: #0bb02f;
$color-red: #f44336;
$color-yellow: #ffa200;
$color-blue: #433af9;
$color-purple: #652d91;

$weight-200: 200;
$weight-300: 300;
$weight-400: 400;
$weight-500: 500;
$weight-600: 600;
$weight-700: 700;
$weight-800: 800;
$weight-900: 900;

$size-10: 10px;
$size-11: 11px;
$size-12: 12px;
$size-13: 13px;
$size-14: 14px;
$size-15: 15px;
$size-16: 16px;
$size-17: 17px;
$size-18: 18px;

@mixin transition($transitioneffects) {
    -webkit-transition: $transitioneffects !important;
    -moz-transition: $transitioneffects !important;
    -o-transition: $transitioneffects !important;
    transition: $transitioneffects !important;
}

@mixin transform($transformeffects) {
    -webkit-transform: $transformeffects !important;
    -moz-transform: $transformeffects !important;
    -o-transform: $transformeffects !important;
    transform: $transformeffects !important;
}

@mixin box-shadow($boxshadoweffects) {
    -webkit-box-shadow: $boxshadoweffects !important;
    -moz-box-shadow: $boxshadoweffects !important;
    -o-box-shadow: $boxshadoweffects !important;
    box-shadow: $boxshadoweffects !important;
}
